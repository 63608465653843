.card{
  border: none;
}
.Button{
  width: 100%;
  margin: 3px;
  border: none;
  height: 30px;
  text-align: left;

}

.ButtonVideo{
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button:focus {outline:0;}

.Bselected{
  background-color: rgb(220, 220, 220) ;;
}
.Vselected{
  background-color: rgb(0, 122, 255);
  color: rgb(255,255,255);
}
.Bnoselected{
  background-color: rgb(245, 245, 245) ;
}

.visible{
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.invisible{
  visibility: hidden;
  opacity: 0;
  height: 0;
  // transition: visibility 0s 2s, opacity 2s linear;

}

.libcontainer {
  position: relative;
  top: 4rem;
  display: flex;
  
  .channels{
    width: 350px;
    height: 80vh;
    padding: 10px;
    overflow: auto;
  }
  .newlib {
    margin: 10px;
    cursor: pointer;
    width: 35px;
    height: 35px;

    :hover {
      box-shadow: 4px 4px 16px rgb(0 0 0 / 20%);
      transform: scale3d(1.01, 1.01, 1.01);
    }
  }
  .Cardcontainer {
    margin-left: auto;
    margin-right: auto;
    // min-width: 250px;
    min-width: 800px;
    width: 70%;
    // height: 80vh;
  }
}
.card-body {
  padding: 0;
  .card-header {
  }

  .video {
    border: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .card-section {
    .card-section-header {
      font-size: medium;
      padding: 5px;
    }
    .card-section-content {
      background-color: white;
      font-size: small;
      padding: 5px;
      min-height: 100px;
    }
  }
  .card-ops {
    display: flex;
    margin: 1rem;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 1.2rem;
      cursor: pointer;

      :hover {
        box-shadow: 4px 4px 16px rgb(0 0 0 / 20%);
        // transform: scale3d(1.01, 1.01, 1.01);
      }
    }
  }
}
